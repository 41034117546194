import axios from '@axios'

export default {
  namespaced: true,
  state: {
    columns:[
      {
        label: 'Nome',
        field: 'name',
      },
      {
        label: 'Email',
        field: 'email',
      },
      {
        label: 'Matrícula',
        field: 'r_code',
      },
    ],
    rows:[],
    last_page:0,
    total:0,
    to:0,
    next_page_url: '',
    prev_page_url: '',
    first_page_url: '',
    prev_page_url: '',
    links:[],
  },
  getters: {},
  mutations: {
    setData(state, payload){
      state.rows.splice(0)
      state.rows.push(...payload.data.data)
      state.links.splice(0)
      state.links.push(...payload.data.links)
      state.last_page = payload.data.last_page | 1
      state.first_page_url = payload.data.first_page_url | ''
      state.next_page_url = payload.data.next_page_url | ''
      state.prev_page_url = payload.data.prev_page_url| ''
      state.to = payload.data.to | 0
      state.total = payload.data.total | 0
    },
    reset(state){
      state.rows.splice(0)
      state.total = 0
      state.to = 0
    }
  },
  actions: {
    listUsersByRoleId(context, {id, params}) {
      if (!id) return

      return new Promise((resolve, reject) => {
        axios.get(`/roles/${id}/list-users`, {params})
          .then(res => {
            context.commit('setData', res)
            resolve(res)
          })
          .catch(err => reject(err))
      });
    },

  },
}
