import axios from '@axios'

export default {
  namespaced: true,
  state: {
    permissions: {},
  },
  getters: {},
  mutations: {
    setPermissions(state, payload) {
      state.permissions = payload
    },
  },
  actions: {
    fetchPermissionsGroupedByModule() {
      return new Promise((resolve, reject) => {
        axios.get('/permission/gruoped-by-module')
          .then(res => {
            this.commit("modalRole/setPermissions", res.data)
            resolve(res)
          })
          .catch(err => reject(err))
      });
    },
    storeRole(context, { role, permissionsSeleted }) {
      const pos = permissionsSeleted.value.indexOf('all')
      if (pos > -1) {
        permissionsSeleted.value.splice(pos, 1)
      }
      return new Promise((resolve, reject) => {
        axios.post('/roles',
          {
            name: role.value.name,
            permissions: permissionsSeleted.value
          }, { showToast: true })
          .then(res => resolve(res))
          .catch(err => reject(err))
      });
    },
    updateRole(context, { role, permissionsSeleted }) {
      const pos = permissionsSeleted.value.indexOf('all')
      if (pos > -1) {
        permissionsSeleted.value.splice(pos, 1)
      }
      return new Promise((resolve, reject) => {
        axios.post('/roles/update/' + role.value.id,
          {
            name: role.value.name,
            permissions: permissionsSeleted.value
          }, { showToast: true })
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    }
  },
}
