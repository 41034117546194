<template>
  <div>
    <p class="mb-2">
      Um perfil fornece acesso a menus e recursos predefinidos
    </p>
    <b-row>
      <b-col
        xl="4"
        lg="6"
        md="6"
      >
        <div class="card">
          <b-row>
            <b-col sm="5">
              <div class="d-flex align-items-end justify-content-center h-100">
                <b-img
                  fluid
                  class="mt-2"
                  width="85"
                  alt="Image"
                  :src="imgRole"
                />
              </div>
            </b-col>
            <b-col sm="7">
              <div class="card-body text-sm-end text-center ps-sm-0">
                <b-link
                  v-b-modal.permission
                  class="stretched-link text-nowrap add-new-role"
                  @click="switchModalType('create')"
                >
                  <span
                    class="
                      btn btn-primary
                      mb-1
                      waves-effect waves-float waves-light
                    "
                  >Adicionar Perfil</span>
                </b-link>
                <p class="mb-0">
                  Adicionar um novo perfil
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col
        v-for="role in roles"
        :key="role.id"
        lg="6"
        md="6"
        xl="4"
      >
        <b-card>
          <div class="d-flex justify-content-between">
            <span>Total {{ role.count_users }} usuários</span>
            <ul
              class="list-unstyled d-flex align-items-center avatar-group mb-0"
            >
              <li
                v-for="user in role.users"
                :key="user.id"
                class="avatar avatar-sm pull-up"
                :data-bs-original-title="user.name"
              >
                <b-avatar
                  v-b-tooltip.hover.v-primary
                  size="30"
                  variant="light-primary"
                  :title="user.name"
                  :src="user.avatar"
                  class="badge-minimal"
                  badge-variant="success"
                />
              </li>
              <li
                v-b-tooltip.hover.top="'Ver mais usuários'"
                class="avatar avatar-sm pull-up"
                @click="showUsers(role.id)"
              >
                <b-avatar
                  style="cursor: pointer"
                  size="30"
                  icon="plus"
                  variant="primary"
                />
              </li>
            </ul>
          </div>
          <div
            class="d-flex justify-content-between align-items-end mt-1 pt-25"
          >
            <div class="role-heading">
              <h4 class="fw-bolder">
                {{ role.name }}
              </h4>
              <b-link
                v-b-modal.permission
                href="#"
                class="role-edit-modal"
                @click="switchModalType('update', role)"
              >
                <small class="fw-bolder"> Editar Perfil </small>
              </b-link>
            </div>
            <b-icon
              style="cursor: pointer"
              variant="danger"
              size="20"
              icon="trash-fill"
              @click="deleteRole(role.id)"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <modal-role
      id="permission"
      :type="modalType"
      :role-selected="roleSelected"
      @modalRoleCreated="fetchRoles()"
    />
    <component
      :is="showModalListusers.component"
      :role-id="showModalListusers.roleId"
      :show="showModalListusers.show"
      @show="showlist($event)"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BImg,
  BModal,
  BFormInput,
  BFormGroup,
  BForm,
  BFormCheckbox,
  BTbody,
  BTr,
  BTd,
  BAvatar,
  BIcon,
  BIconTrashFill,
  BIconPlus,
  VBTooltip,
} from 'bootstrap-vue'
import modalRole from '@/views/permissions/modalRole/modalRole.vue'
import modalListUsersByRole from '@/views/permissions/modalListUsersByRole/modalListUsers.vue'
import {
  computed, onUnmounted, reactive, ref,
} from 'vue-demi'
import store from '@/store'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import roleStoreModule from '../roleStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BImg,
    BModal,
    BFormInput,
    BFormGroup,
    BForm,
    BFormCheckbox,
    BTbody,
    BTr,
    BTd,
    modalRole,
    BAvatar,
    BIcon,
    BIconTrashFill,
    BIconPlus,
    FeatherIcon,
    modalListUsersByRole,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup(props, { root, emit }) {
    const USER_APP_STORE_MODULE_NAME = 'role'
    const imgRole = require('@/assets/images/pages/faq-illustrations.svg')

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) { store.registerModule(USER_APP_STORE_MODULE_NAME, roleStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) { store.unregisterModule(USER_APP_STORE_MODULE_NAME) }
    })
    store.dispatch('role/fetchRoles')
    const fetchRoles = () => {
      store.dispatch('role/fetchRoles')
    }
    const roles = computed(() => store.state.role.roles)
    const roleSelected = ref({})
    const modalType = ref('create')
    const switchModalType = (value, role) => {
      if (value == 'update') {
        roleSelected.value = role
        modalType.value = value
        return
      }
      roleSelected.value = {}
      modalType.value = value
    }
    const deleteRole = id => {
      root.$swal({
        title: 'Você tem certeza?',
        text: 'Você não poderá reverter essa alteração!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero apagar!',
        cancelButtonText: 'Não, eu quero cancelar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('role/deleteRole', { id })
            .then(() => {
              root.$swal({
                icon: 'success',
                title: 'Apagado!',
                text: 'Perfil apagado com sucesso.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    }
    const roleId = ref(0)
    const showModalListusers = reactive({ component: '', roleId: 0, show: false })
    const showUsers = id => {
      modalListUsersByRole.component = ''
      showModalListusers.component = modalListUsersByRole
      showModalListusers.roleId = id
      showModalListusers.show = true
    }
    const showlist = event => {
      showModalListusers.show = event.show
    }

    return {
      roles,
      modalType,
      switchModalType,
      roleSelected,
      fetchRoles,
      deleteRole,
      roleId,
      showUsers,
      showModalListusers,
      showlist,
      imgRole,
    }
  },
}
</script>
<style scoped>
.fw-bolder {
  font-weight: 600 !important;
}
@media (min-width: 576px) {
  .text-sm-end {
    text-align: right !important;
  }
}
</style>
