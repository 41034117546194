<template>
  <div>
    <b-modal v-model="this.show" @show="loadItems" @close.prevent="close" @ok.prevent="close" centered id="modalListUsers" ok-only size="xl">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t("Pesquisar") }}</label>
            <b-form-input
              @input="loadItems"
              v-model="serverParams.search"
              :placeholder="$t('Pesquisar')"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        @on-sort-change="onSorteChange"
        :totalRows="total"
        :isLoading.sync="isLoading"
        :pagination-options="{
          enabled: true
        }"
        :rows="rows"
        :columns="columns"
        :search-options="{
          enabled: true,
          externalQuery: serverParams.search,
        }"
        :sort-options="{
          enabled: true,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <b-avatar class="mx-1" :src="props.row.avatar" />
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>
        </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              {{$t('Mostrando')}} 1 {{ $t('de')}}
            </span>
            <b-form-select
              v-model="serverParams.perPage"
              :options="['5', '10', '15', '20', '30', '40', '50', '100']"
              class="mx-1"
              @change="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> {{$t('de')}} {{ props.total }} {{$t('usuarios')}} </span>
          </div>
          <div>
            <b-pagination
              :value="serverParams.page"
              :total-rows="props.total"
              :per-page="serverParams.perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
      </vue-good-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { ref, onUnmounted, reactive, computed } from "vue-demi";
import { VueGoodTable } from "vue-good-table";
import modalListUsersStoreModule from "../modalListUsersStoreModule";
import store from "@/store";
export default {
  props:{
    roleId:{
      type: Number,
      required: true,
    },
    show:{
      type: Boolean,
      required:false
    }
  },
  components: {
    BModal,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BFormSelect,
  },
  setup(props, {root, emit}) {
    const USER_APP_STORE_MODULE_NAME = "modalListUsersByRoleStore";
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(
        USER_APP_STORE_MODULE_NAME,
        modalListUsersStoreModule
      );
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const showModal = ref(props.show)
    const close = ()=>{
      store.commit('modalListUsersByRoleStore/reset')
      showModal.value = false
      emit('show', {show:false})
    }
    const loadItems = () => {
      store.dispatch('modalListUsersByRoleStore/listUsersByRoleId', {id:props.roleId, params:serverParams});
    }
    const onSorteChange = (params) => {
      serverParams.sort.type = params[0].type
      serverParams.sort.field = params[0].field
      loadItems();
    }
    const onPageChange = (params) =>{
      serverParams.page = params.currentPage
      loadItems();
    }
    const onPerPageChange = (params) => {
      serverParams.perPage = params.currentPerPage
      loadItems();
    }
    const columns = ref(store.state.modalListUsersByRoleStore.columns)
    const rows = ref(store.state.modalListUsersByRoleStore.rows)
    const serverParams = reactive({
      sort:{
          field: '',
          type: ''
        },
      page: 1, 
      perPage: 5,
      search:'',
    })
    const isLoading = ref(false)
    const total = computed(()=>{ return store.state.modalListUsersByRoleStore.total})
    return {
      columns,
      rows,
      close,
      showModal,
      serverParams,
      isLoading,
      loadItems,
      total,
      onSorteChange,
      onPageChange,
      onPerPageChange,
    };
  },
};
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>