<template>
  <b-modal
    hide-footer
    :id="this.id"
    size="lg"
    centered
    header-bg-variant=""
  >
    <div class="text-center mb-4">
      <h1 class="role-title">{{ modalTitle }}</h1>
      <p>Defina as permissões do perfil</p>
    </div>
    <validation-observer v-slot="{ handleSubmit }" >
      <b-form @submit.prevent="handleSubmit(createOrUpdateRole)">
        <b-col cols="12">
          <validation-provider
            #default="{ errors, classes }"
            name="Nome do perfil"
            rules="required"
          >
            <b-form-group label="Nome do perfil" >
                <b-form-input
                :class="classes"
                type="text"
                placeholder="Nome do perfil"
                v-model="role.name"
                ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <h4 class="mt-2 pt-50">Permissões do Perfil</h4>
          <div class="table-responsive">
            <table class="table table-flush-spacing">
              <b-tbody>
                <b-tr>
                  <b-td class="text-nowrap fw-bolder px-1">
                    Super Administrador
                  </b-td>
                  <b-td colspan="4" class="px-1">
                    <b-form-checkbox
                      class="form-check"
                      value="all"
                      name="permissions[]"
                      v-model="permissionsSeleted"
                      @change="selectAllPermissions()"
                      >Selecionar Tudo</b-form-checkbox
                    >
                  </b-td>
                </b-tr>
                <b-tr v-for="(permission, index) in permissions" :key="index">
                  <b-td class="text-nowrap fw-bolder px-1">
                    {{ permission.module }}
                  </b-td>
                  <b-td
                    class="px-1"
                    v-for="permission in permission.permissions"
                    :key="permission.id"
                  >
                    <b-form-checkbox
                      class="form-check"
                      name="permissions[]"
                      :value="permission.name"
                      v-model="permissionsSeleted"
                      >{{ permission.permission }}</b-form-checkbox
                    >
                  </b-td>
                </b-tr>
              </b-tbody>
            </table>
          </div>
        </b-col>
        <b-col cols="12" class="modal-footer">
          <b-button variant="danger" type="button" @click="$bvModal.hide(id)">{{$t('Cancelar')}}</b-button>
          <b-button variant="primary" type="submit">{{$t('Salvar')}}</b-button>
        </b-col>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BForm,
  BFormCheckbox,
  BTbody,
  BTr,
  BTd,
  BButton,
} from "bootstrap-vue";
import { computed, onUnmounted, ref } from "vue-demi";
import store from "@/store";
import modalRoleStoreModule from "../modalRoleStoreModule";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
export default {
  components: {
    BCol,
    BModal,
    BFormInput,
    BFormGroup,
    BForm,
    BFormCheckbox,
    BTbody,
    BTr,
    BTd,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      default: "create",
      validator: (value) => {
        return ["create", "update"].indexOf(value) !== -1;
      },
    },
    roleSelected: {
      type: Object,
      required: false,
    },
  },
  setup(props, {root, emit}) {
    const USER_APP_STORE_MODULE_NAME = "modalRole";
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, modalRoleStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    store.dispatch("modalRole/fetchPermissionsGroupedByModule");

    const permissionsSeleted = ref([]);
    const permissions = computed(() => {
      return store.state.modalRole.permissions;
    });
    const getAllPermissionsToArray = computed(() => {
      let arrPermissions = [];
      if (permissions.value) {
        for (const permissionI of permissions.value) {
          for (const permission of permissionI.permissions) {
            arrPermissions.push(permission.name);
          }
        }
        return arrPermissions;
      }
      return [];
    });

    const modalTitle = computed(() => {
      if (props.type == "create") {
        return "Criar novo perfil";
      }
      return "Atualizar perfil";
    });

    const role = computed(() => {
      if (props.roleSelected) {
        permissionsSeleted.value = props.roleSelected.permissions || [];
        return props.roleSelected;
      }
      return {};
    });
    const selectAllPermissions = () => {
      if (permissionsSeleted.value.indexOf("all") > -1) {
        permissionsSeleted.value.splice(0);
        permissionsSeleted.value.push(...getAllPermissionsToArray.value, "all");
      } else {
        permissionsSeleted.value.splice(0);
      }
    };
    const createOrUpdateRole = () => {
      root.$bvModal.hide(props.id)
      if (role.value.id)
        store
          .dispatch("modalRole/updateRole", { role, permissionsSeleted })
          .then(() => {
            emit("modalRoleCreated", true);
          });
      else
        store
          .dispatch("modalRole/storeRole", { role, permissionsSeleted })
          .then(() => {
            emit("modalRoleCreated", true);
          });
    };
    return {
      permissions,
      modalTitle,
      role,
      permissionsSeleted,
      selectAllPermissions,
      createOrUpdateRole,
      required,
    };
  },
};
</script>

<style scoped>
.fw-bolder {
  font-weight: 600 !important;
}
</style>