var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"centered":"","id":"modalListUsers","ok-only":"","size":"xl"},on:{"show":_vm.loadItems,"close":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)},"ok":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}},model:{value:(this.show),callback:function ($$v) {_vm.$set(this, "show", $$v)},expression:"this.show"}},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t("Pesquisar")))]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":_vm.$t('Pesquisar'),"type":"text"},on:{"input":_vm.loadItems},model:{value:(_vm.serverParams.search),callback:function ($$v) {_vm.$set(_vm.serverParams, "search", $$v)},expression:"serverParams.search"}})],1)])],1),_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.total,"isLoading":_vm.isLoading,"pagination-options":{
        enabled: true
      },"rows":_vm.rows,"columns":_vm.columns,"search-options":{
        enabled: true,
        externalQuery: _vm.serverParams.search,
      },"sort-options":{
        enabled: true,
      }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-sort-change":_vm.onSorteChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{staticClass:"mx-1",attrs:{"src":props.row.avatar}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.name))])],1):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" "+_vm._s(_vm.$t('Mostrando'))+" 1 "+_vm._s(_vm.$t('de'))+" ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['5', '10', '15', '20', '30', '40', '50', '100']},on:{"change":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.serverParams.perPage),callback:function ($$v) {_vm.$set(_vm.serverParams, "perPage", $$v)},expression:"serverParams.perPage"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('de'))+" "+_vm._s(props.total)+" "+_vm._s(_vm.$t('usuarios'))+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":_vm.serverParams.page,"total-rows":props.total,"per-page":_vm.serverParams.perPage,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }