import axios from '@axios'

export default {
  namespaced: true,
  state: {
    roles: {},
  },
  getters: {},
  mutations: {
    setRoles(state, payload) {
      state.roles = payload
    },
    deleteRole(state, { id }) {
      state.roles = state.roles.filter(role => role.id !== id)
    },
  },
  actions: {
    fetchRoles() {
      return new Promise((resolve, reject) => {
        axios
          .get('/roles')
          .then(response => {
            this.commit('role/setRoles', response.data.data)
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
    fetchRole(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/roles', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios.get(`/roles/delete/${id}`, { showToast: true })
          .then(response => {
            this.commit('role/deleteRole', { id })
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
