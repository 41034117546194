import { render, staticRenderFns } from "./modalRole.vue?vue&type=template&id=3242bf22&scoped=true&"
import script from "./modalRole.vue?vue&type=script&lang=js&"
export * from "./modalRole.vue?vue&type=script&lang=js&"
import style0 from "./modalRole.vue?vue&type=style&index=0&id=3242bf22&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3242bf22",
  null
  
)

export default component.exports